<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-form-group
          label="Branch Name"
        >
          <validation-provider
            v-slot="{ errors }"
            vid="branch.name"
            name="Branch Name"
          >
            <b-form-input
              v-model="form.branch.name"
              placeholder="Enter Branch Name"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Phone Number"
        >
          <validation-provider
            v-slot="{ errors }"
            vid="branch.phone"
            name="Phone Number"
          >
            <b-form-input
              v-model="form.branch.phone"
              placeholder="Enter Phone Number"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Unit Number"
        >
          <validation-provider
            v-slot="{ errors }"
            vid="branch.unit"
            name="Unit"
          >
            <b-form-input
              v-model="form.branch.unit"
              placeholder="Enter Unit Number"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Address Line 1"
        >
          <validation-provider
            v-slot="{ errors }"
            vid="branch.address1"
            name="Address Line 1"
          >
            <b-form-input
              v-model="form.branch.address1"
              placeholder="Enter Address Line 1"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Address Line 2"
        >
          <validation-provider
            v-slot="{ errors }"
            vid="branch.address2"
            name="Address Line 2"
          >
            <b-form-input
              v-model="form.branch.address2"
              placeholder="Enter Address Line 2"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Address Line 3 (Optional)"
        >
          <validation-provider
            v-slot="{ errors }"
            vid="branch.address3"
            name="Address Line 3"
          >
            <b-form-input
              v-model="form.branch.address3"
              placeholder="Enter Address Line 3"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Postcode"
        >
          <validation-provider
            v-slot="{ errors }"
            vid="branch.postcode"
            name="Postcode"
          >
            <b-form-input
              v-model="form.branch.postcode"
              placeholder="Enter Postcode"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Area"
        >
          <validation-provider
            v-slot="{ errors }"
            vid="branch.area"
            name="Area"
          >
            <b-form-input
              v-model="form.branch.area"
              placeholder="Enter Area"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="State"
        >
          <MySelectStates
            :vid-name="'branch.state'"
            :state="form.branch.state"
            @set-selected-state="handleSetSelectedState"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Country"
        >
          <MySelectCountries
            :vid-name="'branch.country'"
            :country="form.branch.country"
            @set-selected-country="handleSetSelectedCountry"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import MySelectStates from '@/components/MySelectStates.vue'
import MySelectCountries from '@/components/MySelectCountries.vue'

export default {
  name: 'BranchForm',
  components: {
    ValidationProvider,
    MySelectCountries,
    MySelectStates,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleSetSelectedState(value) {
      this.form.branch.state = value
    },
    handleSetSelectedCountry(value) {
      this.form.branch.country = value
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
