var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Branch Name"}},[_c('validation-provider',{attrs:{"vid":"branch.name","name":"Branch Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Branch Name"},model:{value:(_vm.form.branch.name),callback:function ($$v) {_vm.$set(_vm.form.branch, "name", $$v)},expression:"form.branch.name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Phone Number"}},[_c('validation-provider',{attrs:{"vid":"branch.phone","name":"Phone Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Phone Number"},model:{value:(_vm.form.branch.phone),callback:function ($$v) {_vm.$set(_vm.form.branch, "phone", $$v)},expression:"form.branch.phone"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Unit Number"}},[_c('validation-provider',{attrs:{"vid":"branch.unit","name":"Unit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Unit Number"},model:{value:(_vm.form.branch.unit),callback:function ($$v) {_vm.$set(_vm.form.branch, "unit", $$v)},expression:"form.branch.unit"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Address Line 1"}},[_c('validation-provider',{attrs:{"vid":"branch.address1","name":"Address Line 1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Address Line 1"},model:{value:(_vm.form.branch.address1),callback:function ($$v) {_vm.$set(_vm.form.branch, "address1", $$v)},expression:"form.branch.address1"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Address Line 2"}},[_c('validation-provider',{attrs:{"vid":"branch.address2","name":"Address Line 2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Address Line 2"},model:{value:(_vm.form.branch.address2),callback:function ($$v) {_vm.$set(_vm.form.branch, "address2", $$v)},expression:"form.branch.address2"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Address Line 3 (Optional)"}},[_c('validation-provider',{attrs:{"vid":"branch.address3","name":"Address Line 3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Address Line 3"},model:{value:(_vm.form.branch.address3),callback:function ($$v) {_vm.$set(_vm.form.branch, "address3", $$v)},expression:"form.branch.address3"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Postcode"}},[_c('validation-provider',{attrs:{"vid":"branch.postcode","name":"Postcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Postcode"},model:{value:(_vm.form.branch.postcode),callback:function ($$v) {_vm.$set(_vm.form.branch, "postcode", $$v)},expression:"form.branch.postcode"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Area"}},[_c('validation-provider',{attrs:{"vid":"branch.area","name":"Area"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Area"},model:{value:(_vm.form.branch.area),callback:function ($$v) {_vm.$set(_vm.form.branch, "area", $$v)},expression:"form.branch.area"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"State"}},[_c('MySelectStates',{attrs:{"vid-name":'branch.state',"state":_vm.form.branch.state},on:{"set-selected-state":_vm.handleSetSelectedState}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Country"}},[_c('MySelectCountries',{attrs:{"vid-name":'branch.country',"country":_vm.form.branch.country},on:{"set-selected-country":_vm.handleSetSelectedCountry}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }