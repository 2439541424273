<template>
  <b-modal
    id="new-market-modal"
    ref="new-market-modal"
    v-model="show"
    :title="!isEdit ? 'Add New Branch' : 'Edit Branch'"
    size="lg"
    hide-footer
    no-close-on-backdrop
    centered
    @hidden="handleHiddenModal"
  >
    <ValidationObserver
      ref="branchForm"
      v-slot="{ passes }"
    >
      <form @submit.prevent="passes(isEdit ? handleEditBranch : handleAddNewBranch)">
        <MySelectClients
          v-if="isEdit"
          :client="form.client"
          @set-selected-client="handleSetClient"
        />
        <MarketForm
          :form="form"
          :is-editable="false"
        />
        <hr>
        <BranchForm :form="form" />
        <b-button
          variant="primary"
          class="float-right mt-1"
          type="submit"
          :disabled="isLoading"
        >
          <b-spinner
            v-if="isLoading"
            class="mr-50 p-0"
            small
          />
          <feather-icon
            v-else
            icon="SaveIcon"
            class="mr-50"
          />
          {{ !isEdit ? 'Add New Branch' : 'Update Branch' }}
        </b-button>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import BranchForm from '@/components/BranchForm.vue'
import MarketForm from '@/components/MarketForm.vue'
import MySelectClients from '@/components/MySelectClients.vue'
import axios from '@axios'

export default {
  name: 'AddEditBranchModal',
  components: {
    ValidationObserver,
    BranchForm,
    MarketForm,
    MySelectClients,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      isLoading: false,
    }
  },
  watch: {
    isShow(newValue) {
      this.show = newValue
    },
  },
  methods: {
    handleHiddenModal() {
      this.show = false
      this.$emit('reset-modal')
    },
    async handleAddNewBranch() {
      try {
        this.isLoading = true
        const response = await axios.post(`/client-branches/${this.form.id}`, { branch: this.form.branch })
        if (response.status === 422) {
          this.$refs.branchForm.setErrors(response.data.errors)
          this.isLoading = false
          return
        }
        this.isLoading = false
        this.$swal({
          icon: 'success',
          title: 'New Branch Added',
          text: 'Successfully Added New Branch',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        }).then(() => {
          this.handleHiddenModal()
        })
      } catch (error) {
        throw new Error('Something went wrong.', error)
      }
    },
    async handleEditBranch() {
      try {
        this.isLoading = true
        const response = await axios.patch(`/client-branches/${this.form.branch.id}`, { branch: this.form.branch })
        if (response.status === 422) {
          this.$refs.branchForm.setErrors(response.data.errors)
          this.isLoading = false
          return
        }
        this.isLoading = false
        this.$swal({
          icon: 'success',
          title: 'Branch Updated',
          text: 'Successfully Updated Branch',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        }).then(() => {
          this.handleHiddenModal()
          this.$emit('refetch-branches')
        })
      } catch (error) {
        throw new Error('Something went wrong.', error)
      }
    },
    handleSetClient(client) {
      this.form.image_path = client.logo
      this.form.branch = { ...this.form.branch, client_id: client.id }
      this.form.client = client
    },
  },
}
</script>
